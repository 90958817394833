.containerStyle {
  background: #fff;
  /* width: calc(100% - 40px); */
  width: 100%;
  border-radius: 20px;
  padding: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
