.containerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subContainerStyle {
  width: 24px;
  height: 18px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 7px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chackBoxLableTextStyle {
  color: #000;
  font-family: "Inter-Light", sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0px;
  margin-top: 3px;
  text-align: center;
  width: 65px;
}
