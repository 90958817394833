.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 35%;
    position: relative;
    transition: all 5s ease-in-out;
}

.popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}
.popup .close:hover {
    cursor: pointer;
    color: #000;
}
.popup .content {
    max-height: 30%;
    overflow: auto;
}

/* content styles start*/
.addBrandContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.customInputViewStyle {
    height: 47px;
    border-radius: 7px;
    padding: 0px;
    padding-left: 12px;
    margin-top: 4px;
    border: 0.5px solid rgba(0, 0, 0, 1);
    font-size: 18px;
}
.addProductDetailsStyle {
    font-family: "Inter-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 24px;
    color: black;
}

.lableTextStyle {
    font-family: "Inter-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: black;
}

.btnViewStyle {
    /* width: 200px; */
    width: fit-content;
    border-radius: 30px;
    border: 2px solid #fff;
    background: #000;
}
/* content styles end*/

@media screen and (max-width: 700px) {
    .popup {
        width: 70%;
    }
}
