body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}
/********   fonts  *********/
@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-Semibold";
  src: url("./assets/fonts/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("./assets/fonts/Inter-Medium.ttf");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter-Light";
  src: url("./assets/fonts/Inter-Light.ttf");
}
