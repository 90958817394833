.containerStyle {
    width: 100%;
    margin: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(
        180deg,
        #000 0%,
        rgba(7, 4, 148, 0.76) 52.08%,
        #332fff 100%
    );
}
.subContainerStyle {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    overflow: scroll;
}
.topSectionStyle {
    display: flex;
    flex-direction: row;
    align-items: "center";
    justify-content: flex-start;
    padding: 40px 0px 0px 46px;
}
.registerTextStyle {
    font-family: "Inter-Medium", sans-serif;
    font-weight: 500;
    color: #fff;
    text-align: center;
    font-size: 30px;
    line-height: normal;
    margin: 0;
}
.middelSectionStyle {
    padding-left: 140px;
    padding-right: 140px;
    display: grid;
    grid-template-columns: 44% 44%;
    row-gap: 10px;
    justify-content: space-between;
    margin-top: 20px;
}

.errorMessageStyles {
    font-family: "Inter-Medium", sans-serif;
    color: red;
    text-align: start;
    font-size: 20px;
    line-height: 24px;
}

.eachSectionStyle {
    max-width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}
.eachSectionTitleTextStyle {
    font-family: "Inter-Bold", sans-serif;
    font-weight: 700;
    color: #fff;
    font-size: 20px;
    line-height: normal;
    margin: 0;
}
.inputSectionStyle {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 17px;
}
.passwordSectionStyle {
    width: 100%;
    margin-top: 71px;
    display: flex;
    flex-direction: column;
    gap: 17px;
}
.btnSectionStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 46px;
}
.btnViewStyle {
    width: 125px;
    height: 55px;
    border-radius: 27.5px;
    border: 3px solid #fff;
    background: #000;
}
.signUpCompletedSectionStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.registrationDescTextStyle {
    color: #fff;
    text-align: center;
    font-family: "Inter-Medium", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
}
.loginBtnStyle {
    width: 125px;
    height: 55px;
    border-radius: 27.5px;
    border: 3px solid #fff;
    background: #000;
    margin-top: 50px;
}
