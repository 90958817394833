.btnStyle {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  background-color: #236bfe;
  box-shadow: 0px 4px 16px rgba(35, 107, 254, 0.12);
  color: #ffffff;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}
.primaryBtnStyle {
  background-color: #236bfe;
  box-shadow: 0px 4px 16px rgba(35, 107, 254, 0.12);
  color: #ffffff;
}
.darkBtnStyle {
  background: #1e1e20;
  box-shadow: 0px 4px 16px rgba(30, 30, 32, 0.12);
  color: #ffffff;
}
.whiteBtnStyle {
  background: #ffffff;
  border: 1px solid rgba(30, 30, 32, 0.1);
  box-shadow: 0px 4px 16px rgba(196, 196, 196, 0.12);
  color: #1e1e20;
}
.iconViewStyle {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
